import { WorkSheet, utils } from 'xlsx';
import { Column } from "react-data-grid";

export type Row = any[];
export type AOAColumn = Column<Row>;
type RowCol = { rows: Row[]; columns: AOAColumn[]; };

export function ws_to_rdg(ws: WorkSheet): RowCol {
  /* create an array of arrays */
  const data: Row[] = utils.sheet_to_json(ws, { header: 1, raw: false });

  const headers = data[0];
  const rows = data.slice(1);

  /* create column array */
  const range = utils.decode_range(ws["!ref"]||"A1");
  const columns = Array.from({ length: range.e.c + 1 }, (_, i) => ({
    key: String(i), // RDG will access row["0"], row["1"], etc
    name: headers[i], // the column labels will be based on first row of file
  }));

  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      if(rows[i][j] != undefined && rows[i][j].length > 25){
        rows[i][j] = rows[i][j].substring(0, 25) + '...';
      }
    }
  }

  return { rows, columns }; // these can be fed to setRows / setColumns
}