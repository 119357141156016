import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFilter from '../hooks/useFilter';

export default function IsoCodes() {
    const axiosPrivate = useAxiosPrivate();
    const { filter } = useFilter();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [includeNullIsoCodes, setIncludeNullIsoCodes] = useState(true);
    const [selected, setSelected] = useState([]);
    const [customSelected, setCustomSelected] = useState([]);
    const [isoCodes, setIsoCodes] = useState([]);
    const [allowNullIsoCodesOption, setAllowNullIsoCodesOption] = useState(true);

    useEffect(() => {
        const getIsoCodes = async () => {
            if (allowNullIsoCodesOption === true) {
                if (filter?.includeNullIsoCodes === false) {
                    setIncludeNullIsoCodes(filter.includeNullIsoCodes);
                }
            }
            else {
                setIncludeNullIsoCodes(false);
            }
            if(filter?.formCodes)
            {
                setIsoCodes(filter.formCodes);
                if(filter?.isoCodes.length == filter?.formCodes.length){
                    setSelectAll(true); 
                    setSelected(filter?.formCodes);               
                }
                else
                {
                    setSelectAll(false);
                    var selected = filter?.formCodes.filter(code => filter?.isoCodes.includes(code.value))
                    setCustomSelected(selected);
                    setSelected(selected);
                }                
            }
            else
            {
                try{
                    await axiosPrivate.get('/api/core/isocodes')
                        .then(response =>{
                            var codes = response.data.map(isocode => ({ value: isocode.id, label: isocode.code }));
                            setIsoCodes(codes);
                            setSelected(codes);
                        });
                }
                catch(err){
                    console.log(err);
                }
            }            
        };
        getIsoCodes().then(function(){
            setLoading(false);
        });
    }, []);

    const onChange = (value) => {
        setSelectAll(value);
        if(value == true){
            setSelected(isoCodes);
        }
        else{
            setSelected(customSelected);
        }
    };

    const onSelectChange = (value) => {
        setCustomSelected(value);
        setSelected(value);
    };

    const onIsoCodesCheckboxChange = (e) => {
        setIncludeNullIsoCodes(e.target.checked);
    };  

    return ({
        loading, selected, includeNullIsoCodes, isoCodes, setAllowNullIsoCodesOption,
        render:
        <Row>
            <Col xs="6">
                    <h5>ISO Codes</h5>
                    {
                        allowNullIsoCodesOption ?
                            <div>
                                <input type='checkbox' onChange={onIsoCodesCheckboxChange} checked={includeNullIsoCodes} /> Include Records With No Iso Codes
                            </div>
                            :
                            null
                    }
            </Col>
            <Col xs="6">
                <div>
                    <input className='radio-margin' type='radio' name='isoCodesRadio' onChange={() => onChange(true)} checked={selectAll}/> Select All
                    <input className='radio-margin' type='radio' name='isoCodesRadio' onChange={() => onChange(false)} checked={!selectAll}/> Custom Selection
                </div>
                <div>
                    {!selectAll ? <Select isMulti value={selected} options={isoCodes} onChange={onSelectChange} /> : null}
                </div>
            </Col>
        </Row>
    });
}