import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useAuth from '../../../hooks/useAuth';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Users from '../../Users';
import IsoCodes from '../../IsoCodes';
import KentuckyCodes from '../../KentuckyCodes';
import TrainingCodes from '../../TrainingCodes';
import DateRange from '../../DateRange';

export default function ExternalDetailReportForm() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const users = Users();
    const isoCodes = IsoCodes();
    const kentuckyCodes = KentuckyCodes();
    const trainingCodes = TrainingCodes();
    const dateRange = DateRange();
    const auth = useAuth();

    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();

    const isKy = auth.auth.isKy;
    const isCa = auth.auth.isCa;

    const headers = 
        [
            "Activity",
            "Type",
            "Training Hours",
            "Completion Date",
            "Start Time",
            "End Time",
            "Status",
            "ISO Code",
            "Kentucky Code",
            "CAL-JAC Code",
            "Other Training Codes",
            "Score",
            "Location",
            "Description",
            "Internal Instructors",
            "External Instructors",
            "Department",
            "Personnel ID",
            "Rank"
        ];

    if (!isKy) {
        const kyIdx = headers.indexOf('Kentucky Code');
        headers.splice(kyIdx, 1);
    }
    if (!isCa) {
        const caIdx = headers.indexOf('CAL-JAC Code');
        headers.splice(caIdx, 1);
    }

    useEffect(() => {
        dateRange.setHelpText("Date search is based on the ending date and time of the event");

        if (!users.loading && !isoCodes.loading && !kentuckyCodes.loading && !trainingCodes.loading && !dateRange.loading) {
            setLoading(false);
        }
        setFilter({
            users: users.selectedUsers.map(user => user.value),
            groups: users.selectedGroups.map(group => group.value),
            isoCodes: isoCodes.selected.map(code => code.value),
            kentuckyCodes: kentuckyCodes.selected.map(code => code.value),
            trainingCodes: trainingCodes.selectedTrainingCodes.map(trainingCode => trainingCode.label),
            trainingCodeTypes: trainingCodes.selectedTrainingCodeTypes.map(trainingCodeType => trainingCodeType.label),
            headers: headers,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            includeNullTrainingCodes: trainingCodes.includeNullTrainingCodes,
            includeNullIsoCodes: isoCodes.includeNullIsoCodes,
            includeNullKentuckyCodes: kentuckyCodes.includeNullKentuckyCodes,
            selectAllTrainingCodes: trainingCodes.selectAll,

            selectAllUsers: users.selectAll,
            formUsers: users.users,
            formGroups: users.groups,
            formCodes: isoCodes.isoCodes,
            formKyCodes: kentuckyCodes.kentuckyCodes,
            formTrainingCodes: trainingCodes.trainingCodes,
            formTrainingCodeTypes: trainingCodes.trainingCodeTypes
        });
    }, [
        users.loading,
        users.selectAll,
        users.selectedUsers,
        users.users,
        users.selectedGroups,
        users.groups,
        isoCodes.loading,
        isoCodes.selected,
        isoCodes.isoCodes,
        isoCodes.includeNullIsoCodes,
        kentuckyCodes.loading,
        kentuckyCodes.selected,
        kentuckyCodes.kentuckyCodes,
        kentuckyCodes.includeNullKentuckyCodes,
        trainingCodes.loading,
        trainingCodes.selectAll,
        trainingCodes.includeNullTrainingCodes,
        trainingCodes.selectedTrainingCodes,
        trainingCodes.trainingCodes,
        trainingCodes.selectedTrainingCodeTypes,
        trainingCodes.trainingCodeTypes,
        dateRange.loading,
        dateRange.startDate,
        dateRange.endDate
    ]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendfilter = (
            (
                { users, groups, isoCodes, kentuckyCodes, selectAllTrainingCodes, trainingCodes, trainingCodeTypes, headers, startDate, endDate, includeNullTrainingCodes, includeNullIsoCodes, includeNullKentuckyCodes }) =>
                ({ users, groups, isoCodes, kentuckyCodes, selectAllTrainingCodes, trainingCodes, trainingCodeTypes, headers, startDate, endDate, includeNullTrainingCodes, includeNullIsoCodes, includeNullKentuckyCodes })
        )(filter);

        try {
            await axiosPrivate.post('api/reports/externaldetail', sendfilter);
            navigate('/reports');
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner' /> :
            <Container>
                <Col>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {users.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {isoCodes.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    {
                        isKy ?
                            <FormGroup>
                                <Card>
                                    <CardBody>
                                        <FormGroup>
                                            {kentuckyCodes.render}
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </FormGroup>
                            :
                            null
                    }
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {trainingCodes.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {dateRange.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                </Col>
                <footer>
                    <Form onSubmit={handleSubmit}>
                        <Button color="primary">Generate Report</Button>
                        <Link to="/">
                            <Button className='btn-danger'>Back</Button>
                        </Link>
                    </Form>
                </footer>
            </Container>
    );
}