import { Route, Routes } from 'react-router-dom';
import './custom.css';
import './assets/css/site.css';
import Login from './components/Login';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';

import Layout from './components/Layout';

import Home from './components/Home';
import UserReportsIndex from './components/UserReports/UserReportsIndex';
import UserReportDetail from './components/UserReports/UserReportDetail';

import ReportLayout from './components/ReportLayout';

import ISOSummaryReportForm from './components/Reports/ISOSummary/ISOSummaryReportForm';
import ISOSummaryFilter from './components/Reports/ISOSummary/ISOSummaryFilter';
import ISOSummaryViewOptions from './components/Reports/ISOSummary/ISOSummaryViewOptions';
import ISOSummaryPreview from './components/Reports/ISOSummary/ISOSummaryPreview';
import ISODetailReportForm from './components/Reports/ISODetail/ISODetailReportForm';
import ISODetailFilter from './components/Reports/ISODetail/ISODetailFilter';
import ISODetailViewOptions from './components/Reports/ISODetail/ISODetailViewOptions';
import ISODetailPreview from './components/Reports/ISODetail/ISODetailPreview';
import KentuckyDetailReportForm from './components/Reports/KentuckyDetail/KentuckyDetailReportForm';
import KentuckyDetailFilter from './components/Reports/KentuckyDetail/KentuckyDetailFilter';
import KentuckyDetailViewOptions from './components/Reports/KentuckyDetail/KentuckyDetailViewOptions';
import KentuckyDetailPreview from './components/Reports/KentuckyDetail/KentuckyDetailPreview';
import CaliforniaJACReportForm from './components/Reports/CaliforniaJAC/CaliforniaJACReportForm';
import CaliforniaJACFilter from './components/Reports/CaliforniaJAC/CaliforniaJACFilter';
import CaliforniaJACViewOptions from './components/Reports/CaliforniaJAC/CaliforniaJACViewOptions';
import CaliforniaJACPreview from './components/Reports/CaliforniaJAC/CaliforniaJACPreview';
import ExternalDetailReportForm from './components/Reports/ExternalDetail/ExternalDetailReportForm';
import ExternalDetailFilter from './components/Reports/ExternalDetail/ExternalDetailFilter';
import ExternalDetailViewOptions from './components/Reports/ExternalDetail/ExternalDetailViewOptions';
import ExternalDetailPreview from './components/Reports/ExternalDetail/ExternalDetailPreview';
import DetailedCompletionsReportForm from './components/Reports/DetailedCompletions/DetailedCompletionsReportForm';
import DetailedCompletionsFilter from './components/Reports/DetailedCompletions/DetailedCompletionsFilter';
import DetailedCompletionsViewOptions from './components/Reports/DetailedCompletions/DetailedCompletionsViewOptions';
import DetailedCompletionsPreview from './components/Reports/DetailedCompletions/DetailedCompletionsPreview';
import LearningPlanReportForm from './components/Reports/LearningPlan/LearningPlanReportForm';
import LearningPlanFilter from './components/Reports/LearningPlan/LearningPlanFilter';
import LearningPlanViewOptions from './components/Reports/LearningPlan/LearningPlanViewOptions';
import LearningPlanPreview from './components/Reports/LearningPlan/LearningPlanPreview';
import ChecklistReportForm from './components/Reports/Checklist/ChecklistReportForm';
import ChecklistFilter from './components/Reports/Checklist/ChecklistFilter';
import ChecklistViewOptions from './components/Reports/Checklist/ChecklistViewOptions';
import ChecklistPreview from './components/Reports/Checklist/ChecklistPreview';

function App() {
    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                    <Route path='/' element={<Home />} />
                    <Route path='/reports' element={<UserReportsIndex />} />
                    <Route path='/reports/:id' element={<UserReportDetail />} />
                </Route>
                <Route element={<ReportLayout />}>
                    <Route element={<ISOSummaryFilter />}>
                        <Route path='/isosummary/form' element={<ISOSummaryReportForm />} />
                        <Route path='/isosummary/options' element={<ISOSummaryViewOptions />} />
                        <Route path='/isosummary/preview' element={<ISOSummaryPreview />} />
                    </Route>
                    <Route element={<ISODetailFilter />}>
                        <Route path='/isodetail/form' element={<ISODetailReportForm />} />
                        <Route path='/isodetail/options' element={<ISODetailViewOptions />} />
                        <Route path='/isodetail/preview' element={<ISODetailPreview />} />
                    </Route>
                    <Route element={<KentuckyDetailFilter />}>
                        <Route path='/kentuckydetail/form' element={<KentuckyDetailReportForm />} />
                        <Route path='/kentuckydetail/options' element={<KentuckyDetailViewOptions />} />
                        <Route path='/kentuckydetail/preview' element={<KentuckyDetailPreview />} />
                    </Route>
                    <Route element={<CaliforniaJACFilter />}>
                        <Route path='/californiajac/form' element={<CaliforniaJACReportForm />} />
                        <Route path='/californiajac/options' element={<CaliforniaJACViewOptions />} />
                        <Route path='/californiajac/preview' element={<CaliforniaJACPreview />} />
                    </Route>
                    <Route element={<ExternalDetailFilter />}>
                        <Route path='/externaldetail/form' element={<ExternalDetailReportForm />} />
                        <Route path='/externaldetail/options' element={<ExternalDetailViewOptions />} />
                        <Route path='/externaldetail/preview' element={<ExternalDetailPreview />} />
                    </Route>
                    <Route element={<DetailedCompletionsFilter />}>
                        <Route path='/detailedcompletions/form' element={<DetailedCompletionsReportForm />} />
                        <Route path='/detailedcompletions/options' element={<DetailedCompletionsViewOptions />} />
                        <Route path='/detailedcompletions/preview' element={<DetailedCompletionsPreview />} />
                    </Route>
                    <Route element={<LearningPlanFilter />}>
                        <Route path='/learningplan/form' element={<LearningPlanReportForm />} />
                        <Route path='/learningplan/options' element={<LearningPlanViewOptions />} />
                        <Route path='/learningplan/preview' element={<LearningPlanPreview />} />
                    </Route>
                    <Route element={<ChecklistFilter />}>
                        <Route path='/checklist/form' element={<ChecklistReportForm />} />
                        <Route path='/checklist/options' element={<ChecklistViewOptions />} />
                        <Route path='/checklist/preview' element={<ChecklistPreview />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
