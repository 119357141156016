import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Users from '../../Users';
import KentuckyCodes from '../../KentuckyCodes';
import DateRange from '../../DateRange';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';

export default function KentuckyDetailReportForm() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const users = Users();
    const kentuckyCodes = KentuckyCodes();
    const dateRange = DateRange();

    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();

    const headers =
        [
            "Full Name",
            "Type",
            "Kentucky Code",
            "Activity",
            "Course UID",
            "Training Hours",
            "Completion Date",
            "Start Time",
            "End Time",
            "Status",
            "Other Training Codes",
            "Score",
            "Location",
            "Description",
            "Internal Instructors",
            "External Instructors",
            "Department",
            "Personnel ID",
            "Rank"
        ];

    useEffect(() => {
        if (!users.loading && !kentuckyCodes.loading && !dateRange.loading) {
            setLoading(false);
        }
        setFilter({
            users: users.selectedUsers.map(user => user.value),
            groups: users.selectedGroups.map(group => group.value),
            kentuckyCodes: kentuckyCodes.selected.map(code => code.value),
            headers: headers,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,

            selectAllUsers: users.selectAll,
            formUsers: users.users,
            formGroups: users.groups,
            formCodes: kentuckyCodes.kentuckyCodes
        });

        kentuckyCodes.setAllowNullKentuckyCodesOption(false);
    }, [users.loading,
    users.selectAll,
    users.selectedUsers,
    users.users,
    users.selectedGroups,
    users.groups,
    kentuckyCodes.loading,
    kentuckyCodes.selected,
    kentuckyCodes.kentuckyCodes,
    kentuckyCodes.setAllowNullKentuckyCodesOption,
    dateRange.loading,
    dateRange.startDate,
    dateRange.endDate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendfilter = (
            (
                { users, groups, kentuckyCodes, headers, startDate, endDate }) =>
                ({ users, groups, kentuckyCodes, headers, startDate, endDate })
        )(filter);

        try {
            await axiosPrivate.post('api/reports/kentuckydetail', sendfilter);
            navigate('/reports');
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner' /> :
            <Container>
                <Col>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {users.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {kentuckyCodes.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {dateRange.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                </Col>
                <footer>
                    <Form onSubmit={handleSubmit}>
                        <Button color="primary">Generate Report</Button>
                        <Link to="/">
                            <Button className='btn-danger'>Back</Button>
                        </Link>
                    </Form>
                </footer>
            </Container>
    );
}