import React, { useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Users from '../../Users';
import IsoCodes from '../../IsoCodes';
import DateRange from '../../DateRange';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';

export default function ISOSummaryReportForm() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const users = Users();
    const isoCodes = IsoCodes();
    const dateRange = DateRange();
    
    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();
    

    const headers = 
    [
        "Full Name",
        "Email",
        "Department",
        "Personnel ID",
        "Rank",
        "Shift",
        "Station",
        "Division",
        "Battalion",
        "Company",
        "Platoon",
        "Bureau",
        "EMS Certification",
        "Total Hours",
    ];

    useEffect(() => {
        if(!users.loading && !isoCodes.loading && !dateRange.loading){
            setLoading(false);
        }
        setFilter({
            users: users.selectedUsers.map(user => user.value),
            groups: users.selectedGroups.map(group => group.value),
            isoCodes: isoCodes.selected.map(code => code.value),
            headers: [...headers, ...isoCodes.isoCodes.map(code => code.label)],
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,

            selectAllUsers: users.selectAll,
            formUsers: users.users,            
            formGroups: users.groups,
            formCodes: isoCodes.isoCodes
        });

        isoCodes.setAllowNullIsoCodesOption(false);
    }, [users.loading,
        users.selectAll,
        users.selectedUsers,
        users.users,
        users.selectedGroups,
        users.groups,
        isoCodes.loading,
        isoCodes.selected, 
        isoCodes.isoCodes, 
        isoCodes.setAllowNullIsoCodesOption, 
        dateRange.loading,
        dateRange.startDate, 
        dateRange.endDate]); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendfilter = (
            (
                { users, groups, isoCodes, headers, startDate, endDate }) =>
                ({ users, groups, isoCodes, headers, startDate, endDate })
        )(filter);

        try{
            await axiosPrivate.post('api/reports/isosummary', sendfilter);
            navigate('/reports');
        }
        catch(err){
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner'/> :
        <Container>
            <Col>
                <FormGroup>                            
                    <Card>
                        <CardBody>
                            <FormGroup>
                                {users.render}
                            </FormGroup>
                        </CardBody>
                    </Card>  
                </FormGroup>   
                <FormGroup>
                    <Card>
                        <CardBody>                 
                            <FormGroup>
                                {isoCodes.render}
                            </FormGroup>
                        </CardBody>
                    </Card>
                </FormGroup>  
                <FormGroup> 
                    <Card>
                        <CardBody>
                            <FormGroup>
                                {dateRange.render}
                            </FormGroup>
                        </CardBody>
                    </Card>  
                </FormGroup> 
            </Col>
            <footer>
                <Form onSubmit={handleSubmit}>
                    <Button color="primary">Generate Report</Button>
                    <Link to="/">
                        <Button className='btn-danger'>Back</Button>
                    </Link>
                </Form>
            </footer>
        </Container>
    );
}