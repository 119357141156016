import { createContext, useState } from "react";

const NavBarContext = createContext(null);

export const NavBarProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    return (
        <NavBarContext.Provider value={{ loading, setLoading }}>
            {children}
        </NavBarContext.Provider>
    )
}

export default NavBarContext;