import React, { useEffect, useState } from 'react';
import 'react-data-grid/lib/styles.css';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';

export default function ChecklistsViewOptions() {

    const { filter, setFilter } = useFilter();
    const [recordGrouping, setRecordGrouping] = useState(0);

    useEffect(() => {
        //console.log(filter);
        setRecordGrouping((filter?.recordGrouping ?? 0));
    }, []);

    const onChange = (value) => {
        filter.recordGrouping = value;
        setFilter(filter);
        setRecordGrouping(value);
    };

    const userFields = [
        { id: "1", value: "Full Name" },
        { id: "2", value: "Username" },
        { id: "3", value: "Email" },
        { id: "4", value: "Rank" },
        { id: "5", value: "Shift" },
        { id: "6", value: "Division" },
        { id: "7", value: "Station" },
    ];

    const checklistFields = [
        { id: "8", value: "Checklist Name" },
        { id: "9", value: "Checklist Code" },
        { id: "10", value: "Checklist Description" },
        { id: "11", value: "Checklist Version" },

        { id: "12", value: "Completed Date" },
        { id: "13", value: "Observer Name" },
        { id: "14", value: "Approver Name" },
        { id: "15", value: "Approval Date" },
        { id: "16", value: "Course Name" },

        { id: "17", value: "Question Group" },
        { id: "18", value: "Question" },
        { id: "19", value: "Answer" },
        { id: "20", value: "Note" }
    ];

    return (
        <Container>
            <h1>Report Fields</h1>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <h5>Record Grouping</h5>
                            </Col>
                            <Col xs="6">
                                <div>
                                    <input className='radio-margin' type='radio' name='checklistsRadio' onChange={() => onChange(0)} checked={recordGrouping === 0} /> Group By Checklist, then by User<br />
                                    <input className='radio-margin' type='radio' name='checklistsRadio' onChange={() => onChange(1)} checked={recordGrouping === 1} /> Group By User, then by Checklist
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <h5>User Fields</h5>
                            </Col>
                            <Col xs="6">
                                {userFields.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked={true}
                                                    disabled={true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <h5>Observation Checklist Fields</h5>
                            </Col>
                            <Col xs="6">
                                {checklistFields.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked={true}
                                                    disabled={true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
        </Container>
    );
}