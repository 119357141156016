import React from 'react';
import 'react-data-grid/lib/styles.css';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';

export default function KentuckyDetailViewOptions() {
    const detailData = [
        { id: "1", value: "Activity" },
        { id: "2", value: "Type" },
        { id: "3", value: "Kentucky Code" },
        { id: "4", value: "Course UID" },
        { id: "5", value: "Training Hours" },
        { id: "6", value: "Completion Date" },
        { id: "7", value: "Start Time" },
        { id: "8", value: "End Time" },
        { id: "9", value: "Status" },
        { id: "10", value: "Other Training Codes" },
        { id: "11", value: "Score" },
        { id: "12", value: "Location" },
        { id: "13", value: "Description" },
        { id: "14", value: "Internal Instructors" },
        { id: "15", value: "External Instructors" },
    ];

    const userData = [
        { id: "16", value: "Full Name" },
        { id: "17", value: "Department" },
        { id: "18", value: "Personnel ID" },
        { id: "19", value: "Rank" },
    ];

    return (
        <Container>
            <h1>Report Fields</h1>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <div>Detail Fields</div>
                            </Col>
                            <Col xs="6">
                                {detailData.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked={true}
                                                    disabled={true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <div>User Fields</div>
                            </Col>
                            <Col xs="6">
                                {userData.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked={true}
                                                    disabled={true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
        </Container>
    );
}