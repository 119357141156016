import React, { useEffect } from 'react';
import 'react-data-grid/lib/styles.css';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';

export default function LearningPlanViewOptions() {

    const { filter, setFilter } = useFilter();

    useEffect(() => {
        console.log(filter);
    }, []);

    const userFields = [
      { id: "1", value: "Full Name" }
    ];

    const learningPlanFields = [
        { id: "2", value: "Learning Plan Name" },
        { id: "3", value: "Completion Percentage" },
        { id: "4", value: "Enrollment Status" },
        { id: "5", value: "Hours Required" },
        { id: "6", value: "Hours Completed" },
        { id: "7", value: "Hours Remaining" },
        { id: "8", value: "Course" },
        { id: "9", value: "Category"},
        { id: "10", value: "Training Code" },
        { id: "11", value: "Course Type" },
        { id: "12", value: "Enrollment Date" },
        { id: "13", value: "Active From" },
        { id: "14", value: "Active To" },
        { id: "15", value: "Course Completion Date" }
      ];

    return(
    <Container>
        <h1>Report Fields</h1>
        <FormGroup>
            <Card>
            <CardBody>    
                    <Row>
                        <Col xs="6">
                            <h5>User Fields</h5>
                        </Col>
                        <Col xs="6">
                            {userFields.map((item, index) => {
                                return (
                                    <div key={item.id} className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="options"
                                                value={item.value}
                                                defaultChecked = {true}
                                                disabled = {true}
                                            />
                                            &nbsp;{item.value}
                                        </label>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>   
                </CardBody>
            </Card> 
        </FormGroup>
        <FormGroup>
            <Card>
                <CardBody>    
                    <Row>
                        <Col xs="6">
                            <h5>Learning Plan Fields</h5>
                        </Col>
                        <Col xs="6">
                            {learningPlanFields.map((item, index) => {
                                return (
                                    <div key={item.id} className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="options"
                                                value={item.value}
                                                defaultChecked = {true}
                                                disabled = {true}
                                            />
                                            &nbsp;{item.value}
                                        </label>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>   
                </CardBody>
            </Card>   
        </FormGroup>     
    </Container>
    );
}