import React, { useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Users from '../../Users';
import DateRange from '../../DateRange';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';
import Checklists from '../../Checklists';

export default function ChecklistsReportForm() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const users = Users();
    const checklists = Checklists();
    const dateRange = DateRange();

    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();

    /*
    "Checklist Name",
    "Checklist Code",
    "Checklist Creator Name",
    "Checklist Description",
    "Completion Date",
    "Observer Name",
    "Approver Name",
    "Approval Date",
    "Course Id",

    "Full Name",
    "Username",
    "Email",
    "Rank",
    "Shift",
    "Division",
    "Station",

    "Question Group",
    "Question",
    "Answer",
    "Note"
    */

    const headers0 =
        [
            "Checklist Name",
            "Full Name",
            "Completed Date",

            "Question Group",
            "Question",
            "Answer",
            "Note",

            "Checklist Version",
            "Approver Name",
            "Approval Date",
            "Observer Name",
            "Course Name",

            "Checklist Code",
            "Checklist Description",

            "Username",
            "Email",
            "Rank",
            "Shift",
            "Division",
            "Station"
        ];
    const headers1 =
        [
            "Full Name",
            "Checklist Name",
            "Completed Date",

            "Question Group",
            "Question",
            "Answer",
            "Note",

            "Checklist Version",
            "Approver Name",
            "Approval Date",
            "Observer Name",
            "Course Name",

            "Checklist Code",
            "Checklist Description",

            "Username",
            "Email",
            "Rank",
            "Shift",
            "Division",
            "Station"
        ];

    useEffect(() => {
        if (!users.loading && !checklists.loading) {
            setLoading(false);
        }
        setFilter({
            users: users.selectedUsers.map(user => user.value),
            groups: users.selectedGroups.map(group => group.value),
            checklists: checklists.selected.map(checklist => checklist.value),
            headers: (filter?.recordGrouping ?? 0) === 0 ? headers0 : headers1,
            headers0: headers0,
            headers1: headers1,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            recordGrouping: (filter?.recordGrouping ?? 0),
            includeDeletedChecklists: checklists.includeDeletedChecklists,

            selectAllUsers: users.selectAll,
            selectAllChecklists: checklists.selectAll,
            formUsers: users.users,
            formGroups: users.groups,
            formChecklists: checklists.checklists
        });
    }, [users.loading,
        users.selectAll,
        users.selectedUsers,
        users.users,
        users.selectedGroups,
        users.groups,
        checklists.loading,
        checklists.selectAll,
        checklists.selected,
        checklists.checklists,
        checklists.includeDeletedChecklists,
        dateRange.startDate,
        dateRange.endDate
    ]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        filter.headers = (filter?.recordGrouping ?? 0) === 0 ? headers0 : headers1;

        const sendfilter = (
            (
                { users, groups, checklists, headers, startDate, endDate, includeDeletedChecklists }) =>
                ({ users, groups, checklists, headers, startDate, endDate, includeDeletedChecklists })
        )(filter);

        try {
            await axiosPrivate.post('api/reports/checklist', sendfilter);
            navigate('/reports');
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner' /> :
            <Container>
                <Col>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {checklists.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {users.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {dateRange.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                </Col>
                <footer>
                    <Form onSubmit={handleSubmit}>
                        <Button color="primary">Generate Report</Button>
                        <Link to="/">
                            <Button className='btn-danger'>Back</Button>
                        </Link>
                    </Form>
                </footer>
            </Container>
    );
}