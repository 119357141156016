import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { Col, Row } from 'reactstrap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFilter from '../hooks/useFilter';

export default function Checklists() {
    const axiosPrivate = useAxiosPrivate();
    const { filter } = useFilter();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [includeDeletedChecklists, setIncludeDeletedChecklists] = useState(false);
    const [selected, setSelected] = useState([]);
    const [customSelected, setCustomSelected] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [activeChecklists, setActiveChecklists] = useState([]);
    const [allowDeletedChecklistsOption, setAllowDeletedChecklistsOption] = useState(true);
    const [input, setInput] = useState("");

    useEffect(() => {
        const getChecklists = async () => {
            if (allowDeletedChecklistsOption === true) {
                if (filter?.includeDeletedChecklists === true) {
                    setIncludeDeletedChecklists(filter.includeDeletedChecklists);
                }
            }
            else {
                setIncludeDeletedChecklists(false);
            }
            if (filter?.formChecklists) {
                const activeLists = filter.formChecklists.filter(cl => !cl.isDeleted);
                setChecklists(filter.formChecklists);
                setActiveChecklists(activeLists);

                if (filter?.checklists.length == filter?.formChecklists.length) {
                    setSelectAll(true);
                    setSelected(filter?.formChecklists);
                }
                else if (filter?.checklists.length === activeLists.length && filter?.includeDeletedChecklists === false && filter.selectAllChecklists === true) {
                    setSelectAll(true);
                    setSelected(activeLists);
                }
                else {
                    setSelectAll(false);
                    var selected = filter?.formChecklists.filter(checklist => filter?.checklists.includes(checklist.value))
                    setCustomSelected(selected);
                    setSelected(selected);
                }
            }
            else {
                try {
                    await axiosPrivate.get('/api/core/checklists')
                        .then(response => {
                            //var cls = response.data.map(cl => ({ value: cl.id, label: cl.title }));
                            var cls = response.data.map(cl => ({ value: cl.id, label: cl.title, id: cl.id, title: cl.title, code: cl.code, createdDate: cl.createdDate, createdByName: cl.createdByName, isDeleted: cl.isDeleted }));
                            setChecklists(cls);
                            const activeLists = cls.filter(cl => !cl.isDeleted);
                            setActiveChecklists(activeLists);
                            if (filter?.includeDeletedChecklists === true) {
                                setSelected(cls);
                            } else {
                                setSelected(activeLists);
                            }
                        });
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        getChecklists().then(function () {
            setLoading(false);
        });
    }, []);

    const onSelectAllChange = (value) => {
        setSelectAll(value);

        setSelectedList(value, includeDeletedChecklists);
    };

    const onDeletedChecklistsCheckboxChange = (e) => {
        const newIncludeDeletedChecklists = e.target.checked;
        setIncludeDeletedChecklists(newIncludeDeletedChecklists);

        setSelectedList(selectAll, newIncludeDeletedChecklists);
    };

    const setSelectedList = (toSelectAll, toIncludeDeletedChecklists) =>
    {
        if (toSelectAll && toIncludeDeletedChecklists) {
            setSelected(checklists);
        } else if (toSelectAll && !toIncludeDeletedChecklists) {
            setSelected(activeChecklists);
        } else if (!toSelectAll && toIncludeDeletedChecklists) {
            setSelected(customSelected);
        } else if (!toSelectAll && !toIncludeDeletedChecklists) {
            setCustomSelected(customSelected.filter(cl => !cl.isDeleted));
            setSelected(customSelected.filter(cl => !cl.isDeleted));
        } else {
            //shouldn't get here but
            setSelected([]);
        }
    }

    const onSelectChange = (value) => {
        setCustomSelected(value);
        setSelected(value);
    };

  const onInputChange = (value, action) => {
        // only set the input when the action that caused the
        // change equals to "input-change" and ignore the other
        // ones like: "set-value", "input-blur", and "menu-close"
        if (action.action === "input-change") setInput(value); // <---
    }

    const formatOptionLabel = (option, { context, inputValue, selectValue }) => {
        return (
            <>
                {
                    context == "menu" ?
                        <>
                            <div style={{ fontWeight: "bold" }}>
                                {option.title}
                                <span style={{ fontWeight: "bold", color: "#FF0000" }}>
                                    {option.isDeleted === true ? " (**Deleted**)" : ""}
                                </span>
                            </div>
                            <div style={{ color: "gray" }}>
                                {option.code ? `(${option.code})` : ""}
                            </div>
                            <div style={{ color: "gray" }}>
                                Created {option.createdDate} by {option.createdByName}
                            </div>
                        </>
                        :
                        <>
                            {option.title}
                        </>
                }
            </>
        )
    };

    const filterOptions = (
        //candidate:
        { label, value, data },
        input
    ) => {
        if (!includeDeletedChecklists && data.isDeleted)
            return false;

        const inputValue = input.normalize().trim().toLowerCase();

        const isInFields =
            data.title.toLowerCase().includes(inputValue) ||
            data?.code.toLowerCase().includes(inputValue) ||
            data.createdDate.toLowerCase().includes(inputValue) ||
            data.createdByName.toLowerCase().includes(inputValue);

        return isInFields;
    };

    return ({
        loading, selected, checklists, includeDeletedChecklists, setAllowDeletedChecklistsOption, selectAll,
        render:
            <Row>
                <Col xs="6">
                    <h5>Checklists</h5>
                    {
                        allowDeletedChecklistsOption ?
                            <div>
                                <input type='checkbox' onChange={onDeletedChecklistsCheckboxChange} checked={includeDeletedChecklists} /> Include Records from Deleted Checklists
                            </div>
                            :
                            null
                    }
                </Col>
                <Col xs="6">
                    <div>
                        <input className='radio-margin' type='radio' name='checklistsRadio' onChange={() => onSelectAllChange(true)} checked={selectAll} /> Select All
                        <input className='radio-margin' type='radio' name='checklistsRadio' onChange={() => onSelectAllChange(false)} checked={!selectAll} /> Custom Selection
                    </div>
                    <div>
                        {!selectAll ? <Select
                            isMulti
                            closeMenuOnSelect={false}
                            value={selected}
                            options={checklists}
                            onChange={onSelectChange}
                            inputValue={input}
                            onInputChange={onInputChange}
                            formatOptionLabel={formatOptionLabel}
                            filterOption={filterOptions} />
                            : null}
                    </div>
                </Col>
            </Row>
    });
}