import React, { useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Users from '../../Users';
import DateRange from '../../DateRange';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';
import LearningPlans from '../../LearningPlans';

export default function LearningPlanReportForm() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const users = Users();
    const learningPlans = LearningPlans();
    
    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();
    

    const headers = 
    [
        "Full Name",
        "Learning Plan Name",
        "Completion Percentage",
        "Enrollment Status",
        "Hours Required",
        "Hours Completed",
        "Hours Remaining",
        "Course",
        "Category",
        "Training Code",
        "Course Type",
        "Enrollment Date",
        "Active From",
        "Active To",
        "Course Completion Date"
    ];    

    useEffect(() => {
        if(!users.loading && !learningPlans.loading){
            setLoading(false);
        }
        setFilter({
            users: users.selectedUsers.map(user => user.value),
            groups: users.selectedGroups.map(group => group.value),
            learningPlans: learningPlans.selected.map(learningPlan => learningPlan.value),
            headers: headers,

            selectAllUsers: users.selectAll,
            formUsers: users.users,            
            formGroups: users.groups,
            formLearningPlans: learningPlans.learningPlans
        });
    }, [users.loading,
        users.selectAll,
        users.selectedUsers,
        users.users,
        users.selectedGroups,
        users.groups,
        learningPlans.loading,
        learningPlans.selected, 
        learningPlans.learningPlans]); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendfilter = (
            (
                { users, groups, learningPlans, headers }) =>
                ({ users, groups, learningPlans, headers })
        )(filter);

        try{
            await axiosPrivate.post('api/reports/learningplan', sendfilter);
            navigate('/reports');
        }
        catch(err){
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner'/> :
        <Container>
            <Col>
                <FormGroup>
                    <Card>
                        <CardBody>                 
                            <FormGroup>
                                {learningPlans.render}
                            </FormGroup>
                        </CardBody>
                    </Card>
                </FormGroup>  
                <FormGroup>                            
                    <Card>
                        <CardBody>
                            <FormGroup>
                                {users.render}
                            </FormGroup>
                        </CardBody>
                    </Card>  
                </FormGroup>   
            </Col>
            <footer>
                <Form onSubmit={handleSubmit}>
                    <Button color="primary">Generate Report</Button>
                    <Link to="/">
                        <Button className='btn-danger'>Back</Button>
                    </Link>
                </Form>
            </footer>
        </Container>
    );
}