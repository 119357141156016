import React from 'react';
import 'react-data-grid/lib/styles.css';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';

export default function CaliforniaJACViewOptions() {

    const userFields = [
        { id: "1", value: "Apprentice ID" },
        { id: "2", value: "Agency ID" },
    ];

    const CaliforniaJACTrainingFields = [
        { id: "3", value: "Training Date" },
        { id: "4", value: "Training Code" },
        { id: "5", value: "Training Hours" },
    ];

    return (
        <Container>
            <h1>Report Fields</h1>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <h5>User Fields</h5>
                            </Col>
                            <Col xs="6">
                                {userFields.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked={true}
                                                    disabled={true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="6">
                                <h5>California JAC Training Fields</h5>
                            </Col>
                            <Col xs="6">
                                {CaliforniaJACTrainingFields.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked={true}
                                                    disabled={true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </FormGroup>
        </Container>
    );
}