import React from 'react';
import 'react-data-grid/lib/styles.css';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';
import useAuth from '../../../hooks/useAuth';

export default function ExternalDetailViewOptions() {
    const auth = useAuth();

    const isKy = auth?.auth.isKy;
    const isCa = auth?.auth.isCa;

    const detailData = [
        { id: "1", value: "Activity" },
        { id: "2", value: "Type" },
        { id: "3", value: "Training Hours" },
        { id: "4", value: "Completion Date" },
        { id: "5", value: "Start Time" },
        { id: "6", value: "End Time" },
        { id: "7", value: "Status" },
        { id: "8", value: "ISO Code" },
        { id: "9", value: "Kentucky Code" },
        { id: "10", value: "CAL-JAC Code" },
        { id: "11", value: "Other Training Codes" },
        { id: "12", value: "Score" },
        { id: "13", value: "Location" },
        { id: "14", value: "Description" },
        { id: "15", value: "Internal Instructors" },
        { id: "16", value: "External Instructors" },
    ];

    if (!isKy) {
        const kyIdx = detailData.findIndex(obj => obj.value === "Kentucky Code");
        detailData.splice(kyIdx, 1);
    }
    if (!isCa) {
        const caIdx = detailData.findIndex(obj => obj.value === "CAL-JAC Code");
        detailData.splice(caIdx, 1);
    }

    const userData = [
        { id: "17", value: "Department" },
        { id: "18", value: "Personnel ID" },
        { id: "19", value: "Rank" },
    ];

    return(
        <Container>
            <h1>Report Fields</h1>
            <FormGroup>
                <Card>
                <CardBody>    
                        <Row>
                            <Col xs="6">
                                <div>Detail Fields</div>
                            </Col>
                            <Col xs="6">
                                {detailData.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked = {true}
                                                    disabled = {true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>   
                    </CardBody>
                </Card>
            </FormGroup>
            <FormGroup>
                <Card>
                <CardBody>    
                        <Row>
                            <Col xs="6">
                                <div>User Fields</div>
                            </Col>
                            <Col xs="6">
                                {userData.map((item, index) => {
                                    return (
                                        <div key={item.id} className="checkbox-container">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="options"
                                                    value={item.value}
                                                    defaultChecked = {true}
                                                    disabled = {true}
                                                />
                                                &nbsp;{item.value}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>   
                    </CardBody>
                </Card>  
            </FormGroup>        
        </Container>
    );
}