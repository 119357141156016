import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import useFilter from '../hooks/useFilter';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function Categories() {
    const { filter } = useFilter();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [customSelectedCategories, setCustomSelectedCategories] = useState([]);
    const [courseCategories, setCourseCategories] = useState([]);

    useEffect(() => {
        const getCourseCategories = async () => {
            if (filter?.formCourseCategories) {
                setCourseCategories(filter.formCourseCategories);
                if (filter?.selectAllCategories) {
                    setSelectAll(true);
                    setSelectedCategories(expandCategoryList(filter?.formCourseCategories));
                }
                else {
                    setSelectAll(false);
                    const fullFlatList = expandCategoryList(filter?.formCourseCategories);
                    var selected = fullFlatList.filter(category => filter?.courseCategories.includes(category.value))
                    setCustomSelectedCategories(selected);
                    setSelectedCategories(selected);
                }
            }
            else {
                try {
                    await axiosPrivate.get('/api/core/courses/categories')
                        .then(response => {
                            const courseCategoryGroups = response.data;
                            //const emsTree = courseCategoryGroups.EMS;
                            //const fireTree = courseCategoryGroups.Fire;
                            //const complianceTree = courseCategoryGroups.Compliance;
                            //const customTree = courseCategoryGroups.Custom;

                            setCourseCategories(courseCategoryGroups);
                            setSelectedCategories(expandCategoryList(courseCategoryGroups));
                        });
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        Promise.all([getCourseCategories()]).then(function () {
            setLoading(false);
        });
    }, []);

    const expandCategoryList = (category) => {
        var result = category.map((x) => x.options).flat(1);
        return result;
    };

    const onSelectAllChange = (value) => {
        setSelectAll(value);
        if (value === true) {
            setSelectedCategories(expandCategoryList(courseCategories));
        }
        else {
            setSelectedCategories(customSelectedCategories)
        }
    };

    const onSelectedCategoriesChange = (value) => {
        setCustomSelectedCategories(value);
        setSelectedCategories(value);
    };

    const onClickGroupLabel = (options) => {
        setCustomSelectedCategories(value =>
            value.concat(options.filter(grpOpt => !value.includes(grpOpt)))
        );
        setSelectedCategories(value =>
            value.concat(options.filter(grpOpt => !value.includes(grpOpt)))
        );
    };

    const formatGroupLabel = (option) => {
        return (
            <>
                <div style={{ color: "#000000", fontWeight: "bold", fontSize: "18px", backgroundColor: "#dc3545" }} onClick={() => { onClickGroupLabel(option.options); }}>
                    {option.label}
                </div>
            </>
        )
    };
    const formatOptionLabel = (option, { context, inputValue, selectValue }) => {
        return (
            <>
                {
                    context == "menu" ?
                        <>
                            <div style={{ paddingLeft:"25px" }}>
                                {option.label}
                            </div>
                        </>
                        :
                        <>
                            {option.label}
                        </>
                }
            </>
        )
    };

    const sortOptions = (options) => {
        return options.sort((a, b) => a.label.localeCompare(b.label));
    }
    return ({
        loading, selectAll, selectedCategories, courseCategories,
        render:
            <Row>
                <Col xs="6">
                    <h5>Course Categories</h5>
                </Col>
                <Col xs="6">
                    <div>
                        <input className='radio-margin' type='radio' name='categoriesRadio' onChange={() => onSelectAllChange(true)} checked={selectAll} /> Select All
                        <input className='radio-margin' type='radio' name='categoriesRadio' onChange={() => onSelectAllChange(false)} checked={!selectAll} /> Custom Selection
                    </div>
                    <div>
                        {!selectAll ?
                            <div>
                                <label>Categories</label>
                                <Select id="courseCategorySelect" isMulti value={selectedCategories} options={sortOptions(courseCategories)} onChange={onSelectedCategoriesChange}
                                    formatGroupLabel={formatGroupLabel}
                                    formatOptionLabel={formatOptionLabel}
                                />
                            </div>
                            : null}
                    </div>
                </Col>
            </Row>
    });
}