import { Container } from "reactstrap"

const Unauthorized = () => {
    return (
        <Container>
            <h1>Unauthorized</h1>
            <br />
            <p>You do not have access to the requested page.</p>
        </Container>
    )
}

export default Unauthorized