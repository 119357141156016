import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import useFilter from '../hooks/useFilter';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function CourseTypes() {
    const { filter } = useFilter();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);

    const [selectedTypes, setSelectedTypes] = useState([]);
    const [customSelectedTypes, setCustomSelectedTypes] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);

    useEffect(() => {
        const getCourseTypes = async () => {
            if (filter?.formCourseTypes) {
                setCourseTypes(filter.formCourseTypes);
                if (filter?.selectAllCourseTypes) {
                    setSelectAll(true);
                    setSelectedTypes(filter.formCourseTypes)
                }
                else {
                    setSelectAll(false);
                    var selected = filter?.formCourseTypes.filter(courseType => filter?.courseTypes.includes(courseType.value))
                    setCustomSelectedTypes(selected);
                    setSelectedTypes(selected);
                }
            }
            else {
                var courseTypesList = [
                    { value: 1, label: "E-Learning" },
                    { value: 2, label: "Instructor-Led Training (ILT)" },
                    { value: 3, label: "External Training" },
                ];
                setCourseTypes(courseTypesList);
                setSelectedTypes(courseTypesList);
            }
        };

        Promise.all([getCourseTypes()]).then(function () {
            setLoading(false);
        });
    }, []);

    const onSelectAllChange = (value) => {
        setSelectAll(value);
        if (value === true) {
            setSelectedTypes(courseTypes);
        }
        else {
            setSelectedTypes(customSelectedTypes);
        }
    };

    const onSelectedTypesChange = (value) => {
        setCustomSelectedTypes(value);
        setSelectedTypes(value);
    };

    const sortOptions = (options) => {
        return options.sort((a, b) => a.label.localeCompare(b.label));
    }
    return ({
        loading, selectAll, selectedTypes, courseTypes,
        render:
            <Row>
                <Col xs="6">
                    <h5>Course Types</h5>
                </Col>
                <Col xs="6">
                    <div>
                        <input className='radio-margin' type='radio' name='courseTypesRadio' onChange={() => onSelectAllChange(true)} checked={selectAll} /> Select All
                        <input className='radio-margin' type='radio' name='courseTypesRadio' onChange={() => onSelectAllChange(false)} checked={!selectAll} /> Custom Selection
                    </div>
                    <div>
                        {!selectAll ?
                            <div>
                                <label>Types</label>
                                <Select isMulti value={selectedTypes} options={sortOptions(courseTypes)} onChange={onSelectedTypesChange} />
                            </div>
                            : null}
                    </div>
                </Col>
            </Row>
    });
}