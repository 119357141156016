import React from 'react';
import { Outlet } from 'react-router-dom';
import ReportNavMenu from './ReportNavMenu';
import { NavBarProvider } from '../context/NavBarProvider';

export default function ReportLayout() {

    return (
        <NavBarProvider>
          <ReportNavMenu/> 
          <Outlet />
        </NavBarProvider>
    );
}
