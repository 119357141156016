import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFilter from '../hooks/useFilter';

export default function KentuckyCodes() {
    const axiosPrivate = useAxiosPrivate();
    const { filter } = useFilter();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [includeNullKentuckyCodes, setIncludeNullKentuckyCodes] = useState(true);
    const [selected, setSelected] = useState([]);
    const [customSelected, setCustomSelected] = useState([]);
    const [kentuckyCodes, setKentuckyCodes] = useState([]);
    const [allowNullKentuckyCodesOption, setAllowNullKentuckyCodesOption] = useState(true);

    useEffect(() => {
        const getKentuckyCodes = async () => {
            if (allowNullKentuckyCodesOption === true) {
                if (filter?.includeNullKentuckyCodes === false) {
                    setIncludeNullKentuckyCodes(filter.includeNullKentuckyCodes);
                }
            }
            else {
                setIncludeNullKentuckyCodes(false);
            }
            if (filter?.formKyCodes) {
                setKentuckyCodes(filter.formKyCodes);
                if (filter?.kentuckyCodes.length == filter?.formKyCodes.length) {
                    setSelectAll(true);
                    setSelected(filter?.formKyCodes);
                }
                else {
                    setSelectAll(false);
                    var selected = filter?.formKyCodes.filter(code => filter?.kentuckyCodes.includes(code.value))
                    setCustomSelected(selected);
                    setSelected(selected);
                }
            }
            else {
                try {
                    await axiosPrivate.get('/api/core/isocodes/kentuckyCodes')
                        .then(response => {
                            var codes = response.data.map(kentuckyCodes => ({ value: kentuckyCodes.id, label: kentuckyCodes.code }));
                            setKentuckyCodes(codes);
                            setSelected(codes);
                        });
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        getKentuckyCodes().then(function () {
            setLoading(false);
        });
    }, []);

    const onChange = (value) => {
        setSelectAll(value);
        if (value == true) {
            setSelected(kentuckyCodes);
        }
        else {
            setSelected(customSelected);
        }
    };

    const onSelectChange = (value) => {
        setCustomSelected(value);
        setSelected(value);
    };

    const onKyCodesCheckboxChange = (e) => {
        setIncludeNullKentuckyCodes(e.target.checked);
    };

    return ({
        loading, selected, includeNullKentuckyCodes, kentuckyCodes, setAllowNullKentuckyCodesOption,
        render:
            <Row>
                <Col xs="6">
                    <h5>Kentucky Codes</h5>
                    {
                        allowNullKentuckyCodesOption ?
                            <div>
                                <input type='checkbox' onChange={onKyCodesCheckboxChange} checked={includeNullKentuckyCodes} /> Include Records With No Kentucky Codes
                            </div>
                            :
                            null
                    }
                </Col>
                <Col xs="6">
                    <div>
                        <input className='radio-margin' type='radio' name='kentuckyCodesRadio' onChange={() => onChange(true)} checked={selectAll} /> Select All
                        <input className='radio-margin' type='radio' name='kentuckyCodesRadio' onChange={() => onChange(false)} checked={!selectAll} /> Custom Selection
                    </div>
                    <div>
                        {!selectAll ? <Select isMulti value={selected} options={kentuckyCodes} onChange={onSelectChange} /> : null}
                    </div>
                </Col>
            </Row>
    });
}