import { createContext, useState } from "react";

const AuthContext = createContext({
    auth: {
        user: "",
        role: "",
        accessToken: "",
        isKy: false,
        isCa: false
    }
    ,
    setAuth: (/** @type {any} */ auth) => { }
});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;