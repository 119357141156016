import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useFilter from '../hooks/useFilter';
import { Col, Row } from 'reactstrap';

export default function DateRange() {
    const { filter } = useFilter();
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [helpText, setHelpText] = useState("");

    useEffect(() => {
        if(filter?.startDate){
            setStartDate(filter?.startDate);
        }
        if(filter?.endDate){
            setEndDate(filter?.endDate);
        }
        setLoading(false);
    }, []); 

    const onStartChangeDate = (e) => {
        if(e.target.value > endDate){
            return
        } 
        setStartDate(e.target.value);
    };
    const onEndChangeDate = (e) => {
        if(e.target.value < startDate){
            return
        } 
        setEndDate(e.target.value);
    };

    return ({
        loading, startDate, endDate, setHelpText,
        render:
            <Row>
                <Col xs="6">
                    <h5>Date Range</h5>
                    {helpText}
                </Col>
                <Col xs="6">
                    <div>
                        <label>From</label>
                        <input className='dateRange-margin' name='startDate' type='date' value={startDate} onChange={onStartChangeDate} />
                        <label>To</label>
                        <input className='dateRange-margin' name='endDate' type='date' value={endDate} onChange={onEndChangeDate} />
                    </div>
                </Col>
            </Row>
    }
    );
}