import React, { useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Users from '../../Users';
import DateRange from '../../DateRange';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';

export default function CaliforniaJACReportForm() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const users = Users();
    const dateRange = DateRange();

    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();

    const headers =
        [
            "Apprentice ID",
            "Agency ID",

            "Training Date",
            "Training Code",
            "Training Hours",
        ];

    useEffect(() => {
        if (!users.loading) {
            setLoading(false);
        }
        setFilter({
            users: users.selectedUsers.map(user => user.value),
            groups: users.selectedGroups.map(group => group.value),
            headers: headers,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,

            selectAllUsers: users.selectAll,
            formUsers: users.users,
            formGroups: users.groups,
        });
    }, [users.loading,
        users.selectAll,
        users.selectedUsers,
        users.users,
        users.selectedGroups,
        users.groups,
        dateRange.startDate,
        dateRange.endDate
    ]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendfilter = (
            (
                { users, groups, headers, startDate, endDate }) =>
                ({ users, groups, headers, startDate, endDate })
        )(filter);

        try {
            await axiosPrivate.post('api/reports/californiajac', sendfilter);
            navigate('/reports');
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner' /> :
            <Container>
                <Col>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {users.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    {dateRange.render}
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>
                </Col>
                <footer>
                    <Form onSubmit={handleSubmit}>
                        <Button color="primary">Generate Report</Button>
                        <Link to="/">
                            <Button className='btn-danger'>Back</Button>
                        </Link>
                    </Form>
                </footer>
            </Container>
    );
}