import React, { useEffect } from 'react';
import 'react-data-grid/lib/styles.css';
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';

export default function ISOSummaryViewOptions() {

    const userFields = [
        { id: "1", value: "Full Name" },
        { id: "2", value: "Email" },
        { id: "3", value: "Department" },
        { id: "4", value: "Personnel ID" },
        { id: "5", value: "Rank" },
        { id: "6", value: "Shift" },
        { id: "7", value: "Station" },
        { id: "8", value: "Division" },
        { id: "9", value: "Battalion" },
        { id: "10", value: "Company" },
        { id: "11", value: "Platoon" },
        { id: "12", value: "Bureau" },
        { id: "13", value: "EMS Certification" },
    ];

    const isoCodeFields = [
        { id: "14", value: "Total Hours" },
        { id: "15", value: "Facility Training" },
        { id: "16", value: "Company Training" },
        { id: "17", value: "Officer Certification" },
        { id: "18", value: "Officer Continuing Education" },
        { id: "19", value: "New Driver/ Operator Training Program" },
        { id: "20", value: "Existing Driver/Operator Training" },
        { id: "21", value: "Hazardous Materials Training" },
        { id: "22", value: "Recruit Training Program" },
        { id: "23", value: "Building Familiarization for Pre-Incident Planning" },
        { id: "24", value: "Fire Inspector" },
        { id: "25", value: "Public Educator" },
        { id: "26", value: "Fire Investigator" }
      ];

    return(
    <Container>
        <h1>Report Fields</h1>
        <FormGroup>
            <Card>
            <CardBody>    
                    <Row>
                        <Col xs="6">
                            <h5>User Fields</h5>
                        </Col>
                        <Col xs="6">
                            {userFields.map((item, index) => {
                                return (
                                    <div key={item.id} className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="options"
                                                value={item.value}
                                                defaultChecked = {true}
                                                disabled = {true}
                                            />
                                            &nbsp;{item.value}
                                        </label>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>   
                </CardBody>
            </Card> 
        </FormGroup>
        <FormGroup>
            <Card>
                <CardBody>    
                    <Row>
                        <Col xs="6">
                            <h5>ISO Code Fields</h5>
                        </Col>
                        <Col xs="6">
                            {isoCodeFields.map((item, index) => {
                                return (
                                    <div key={item.id} className="checkbox-container">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="options"
                                                value={item.value}
                                                defaultChecked = {true}
                                                disabled = {true}
                                            />
                                            &nbsp;{item.value}
                                        </label>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>   
                </CardBody>
            </Card>   
        </FormGroup>     
    </Container>
    );
}