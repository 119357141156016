import { Outlet } from "react-router-dom";
import { FilterProvider } from "../../../context/FilterProvider";

const KentuckyDetailFilter = () => {
    return (
        <FilterProvider>
            <Outlet />
        </FilterProvider>
    );
}

export default KentuckyDetailFilter;