import React from 'react';
import { Navbar, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './NavMenu.css';

export default function NavMenu() {

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink className="nav-link" to="/">Reports</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="nav-link" to="/reports">User Reports</NavLink>
                        </NavItem>
                    </ul>
            </Navbar>
        </header>
    );
}
