import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Input, Row } from 'reactstrap';
import { RichTreeView } from '@mui/x-tree-view';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFilter from '../hooks/useFilter';
import useAuth from '../hooks/useAuth';

export default function Users() {
    const { auth, setAuth } = useAuth();
    const { filter } = useFilter();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [customSelectedUsers, setCustomSelectedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [customSelectedGroups, setCustomSelectedGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [expandedBranches, setExpandedBranches] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [customSelectedBranches, setCustomSelectedBranches] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchIds, setBranchIds] = useState([]);
    const [allowBranchesOption, setAllowBranchesOption] = useState(false);
    const [treeData, setTreeData] = useState(branches)

    useEffect(() => {
        const getUsers = async () => {
            if (filter?.formUsers) {
                setUsers(filter.formUsers);
                if (filter?.selectAllUsers) {
                    setSelectAll(true);
                    setSelectedUsers(filter.formUsers);
                }
                else {
                    setSelectAll(false);
                    var selected = filter.formUsers.filter(user => filter.users.includes(user.value))
                    setCustomSelectedUsers(selected);
                    setSelectedUsers(selected);
                }
            }
            else {
                try {
                    await axiosPrivate.post('/api/core/users', {})
                        .then(response => {
                            const userOptions = response.data.map(user => ({ value: user.id, label: `${user.lastName}, ${user.firstName}` }))

                            setUsers(userOptions);
                            setSelectedUsers(userOptions);
                        });
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        const getGroups = async () => {
            if (filter?.formGroups) {
                setGroups(filter.formGroups);
                if (filter?.selectAllUsers) {
                    setSelectAll(true);
                    setSelectedGroups(filter.formGroups);
                }
                else {
                    setSelectAll(false);
                    var selected = filter.formGroups.filter(user => filter.groups.includes(user.value))
                    setCustomSelectedGroups(selected);
                    setSelectedGroups(selected);
                }
            }
            else {
                try {
                    await axiosPrivate.get('/api/core/groups')
                        .then(response => {
                            var groupOptions = response.data.map(group => ({ value: group.id, label: group.name }))
                            setGroups(groupOptions);
                            setSelectedGroups(groupOptions);
                        });
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        const getBranches = async () => {
            if (filter?.formBranches) {
                /*
                here:
                    branches - full tree of objects
                    selectedBranches - list of int (selected)
                    branchIds - list of int (full)
                filter:
                    branches - list of int (selected) (same as selectedBranches above)
                    formBranches - full tree of objects (same as branches above)
                    formBranchIds - list of int (full)
                */
                setBranches(filter.formBranches);
                setTreeData(filter.formBranches);
                setBranchIds(filter.formBranchIds);
                setExpandedBranches(filter?.formExpandedBranches);

                if (filter?.selectAllUsers) {
                    setSelectAll(true);
                    setSelectedBranches(filter.formBranchIds);
                }
                else {
                    setSelectAll(false);
                    setCustomSelectedBranches(filter.branches);
                    setSelectedBranches(filter.branches);
                }
            }
            else {
                try {
                    await axiosPrivate.get('/api/core/branches')
                        .then(response => {
                            var branchOptions = buildTree(response.data);
                            setBranches(branchOptions);
                            setTreeData(branchOptions);
                            setBranchIds(branchOptions[0].allBranchIds);
                            setSelectedBranches(branchOptions[0].allBranchIds);
                        });
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        Promise.all([getUsers(), getGroups(), getBranches()]).then(function () {
            setLoading(false);
        });
    }, []);

    const buildTree = (branches) => {
        if (typeof branches === 'undefined' || branches === null || !Array.isArray(branches)) return [];
        var tree = branches.map(branch => ({ id: branch.id, itemId: branch.id.toString(), value: branch.id, label: branch.name, idParent: branch.idParent, children: buildTree(branch.children), allBranchIds: branch.allBranchIds }));
        tree = sortOptions(tree);
        return tree;
    };

    const validateName = (name) => {
        if (name.length <= 1) {
            return;
        }

        // Regular expression to check if a string contains only alphanumeric characters
        const alphanumericRegex = /^[a-zA-Z0-9- ]*$/;
        return alphanumericRegex.test(name);
    }

    const sortOptions = (options) => {
        if (typeof options === 'undefined' || options === null || !Array.isArray(options)) return [];
        return options.sort((a, b) => a.label.localeCompare(b.label));
    }

    const onExpandedBranchesChange = (event, values) => {
        setExpandedBranches(values);
    };

    const onSelectAllChange = (value) => {
        setSelectAll(value);
        if (value === true) {
            setSelectedUsers(users);
            setSelectedGroups(groups);
            setSelectedBranches(branchIds);
        }
        else {
            setSelectedUsers(customSelectedUsers);
            setSelectedGroups(customSelectedGroups);
            setSelectedBranches(customSelectedBranches);
        }
    };

    const onSelectedUsersChange = (value) => {
        setCustomSelectedUsers(value);
        setSelectedUsers(value);
    };

    const onSelectedGroupsChange = (value) => {
        setCustomSelectedGroups(value);
        setSelectedGroups(value);
    };

    const onSelectedBranchesChange = (event, values) => {
        //parent/child selection relationship
        var newIds = determineIdsToSet(branches, values, selectedBranches);

        setCustomSelectedBranches(newIds);
        setSelectedBranches(newIds);
    }

    const onClearSelectionClick = (event) => {
        setCustomSelectedBranches([]);
        setSelectedBranches([]);
    };

    const onFilterChange = (e) => {
        setTreeData(filterBy(branches, e.target.value));
        setExpandedBranches(branchIds);
    }
    function filterBy(arr, query) {
        return query
            ? arr.reduce((acc, item) => {
                if (item.children?.length) {
                    const filtered = filterBy(item.children, query)
                    if (filtered.length) return [...acc, { ...item, children: filtered }]
                }

                const { children, ...itemWithoutChildren } = item;
                return item.label?.toLowerCase().includes(query.toLowerCase()) ? [...acc, itemWithoutChildren] : acc
            }, []) : arr
    }

    //following functions handle the parent/child interactions
    //not yet a built in part: https://github.com/mui/mui-x/issues/4821
    //taken from : https://stackblitz.com/edit/mui-react-tree-view-check-children-uncheck-parents?file=Demo.tsx

    function determineIdsToSet(
        items,//: TreeViewBaseItem[],
        newIds,//: string[],
        currentIds,//: string[]
    ) {
        const isDeselectingNode = currentIds.length > newIds.length;
        if (isDeselectingNode) {
            const removed = currentIds.filter((id) => !newIds.includes(id))[0];
            const parentIdsToRemove = getParentIds(items, removed);
            const childIdsToRemove = getSelectedIdsAndChildrenIds(items, [removed]);

            const newIdsWithParentsAndChildrenRemoved = newIds.filter(
                (id) => !parentIdsToRemove.includes(id) && !childIdsToRemove.includes(id)
            );

            return newIdsWithParentsAndChildrenRemoved;
        }

        const added = newIds.filter((id) => !currentIds.includes(id))[0];
        const parent = getParentNode(items, added);
        if (parent) {
            const childIds = parent.children?.map((node) => node.id) ?? [];
            const allChildrenSelected = childIds.every((id) => newIds.includes(id));
            if (allChildrenSelected) {
                return [...getSelectedIdsAndChildrenIds(items, newIds), parent.id];
            }
        }
        return getSelectedIdsAndChildrenIds(items, newIds);
    }

    function getParentIds(
        items,//: TreeViewBaseItem[],
        id//: string
    ) {
        const parentIds = [];//: string[] = [];

        for (const item of items) {
            if (item.children) {
                if (item.children.some((child) => child.id === id)) {
                    // The current item is a parent of the supplied id
                    parentIds.push(item.id);

                    // Recursively call the function for the parent item
                    const grandParentIds = getParentIds(items, item.id);
                    parentIds.push(...grandParentIds);
                } else {
                    // Recursively call the function for the children of the current item
                    const childParentIds = getParentIds(item.children, id);
                    parentIds.push(...childParentIds);
                }
            }
        }

        return parentIds;
    }

    function getParentNode(
        items,//: TreeViewBaseItem[],
        id//: string
    ) { //): TreeViewBaseItem | undefined {
        for (const item of items) {
            if (item.children) {
                if (item.children.some((child) => child.id === id)) {
                    // The current item is the parent of the supplied id
                    return item;
                } else {
                    // Recursively call the function for the children of the current item
                    const parentNode = getParentNode(item.children, id);
                    if (parentNode) {
                        return parentNode;
                    }
                }
            }
        }

        // No parent found
        return undefined;
    }

    function getSelectedIdsAndChildrenIds(
        items,//: TreeViewBaseItem[],
        selectedIds,//: string[]
    ) {
        const selectedIdIncludingChildrenIds = new Set([...selectedIds]);

        for (const item of items) {
            if (selectedIds.includes(item.id)) {
                // Add the current item's id to the result array
                selectedIdIncludingChildrenIds.add(item.id);

                // Recursively call the function for the children of the current item
                if (item.children) {
                    const childrenIds = item.children.map((child) => child.id);
                    const childrenSelectedIds = getSelectedIdsAndChildrenIds(
                        item.children,
                        childrenIds
                    );
                    childrenSelectedIds.forEach((selectedId) =>
                        selectedIdIncludingChildrenIds.add(selectedId)
                    );
                }
            } else if (item.children) {
                // walk the children to see if selections lay in there also
                const childrenSelectedIds = getSelectedIdsAndChildrenIds(
                    item.children,
                    selectedIds
                );
                childrenSelectedIds.forEach((selectedId) =>
                    selectedIdIncludingChildrenIds.add(selectedId)
                );
            }
        }

        return [...Array.from(selectedIdIncludingChildrenIds)];
    }

    return ({
        loading, selectAll, users, selectedUsers, groups, selectedGroups, branches, branchIds, selectedBranches, expandedBranches, setAllowBranchesOption,
        render:
            <Row>
                <Col xs="6">
                    <h5>User Selection</h5>
                </Col>
                <Col xs="6">
                    <div>
                        <input className='radio-margin' type='radio' name='usersRadio' onChange={() => onSelectAllChange(true)} checked={selectAll} /> Select All
                        <input className='radio-margin' type='radio' name='usersRadio' onChange={() => onSelectAllChange(false)} checked={!selectAll} /> Custom Selection
                    </div>
                    <div>
                        {!selectAll ?
                            <div>
                                <label>Users</label>
                                <Select isMulti value={selectedUsers} options={sortOptions(users)} onChange={onSelectedUsersChange} />
                                <label>Groups</label>
                                <Select isMulti value={selectedGroups} options={sortOptions(groups)} onChange={onSelectedGroupsChange} />
                                {(allowBranchesOption && auth.role === "SuperAdmin") ?
                                    <>
                                        <label>Branches ({selectedBranches.length} selected) (<span onClick={onClearSelectionClick}>Clear selection</span>)</label>
                                        <Input placeholder="Type here to search the branches" onChange={onFilterChange} />
                                        <RichTreeView
                                            multiSelect
                                            checkboxSelection={true}
                                            items={treeData}
                                            selectedItems={selectedBranches}
                                            expandedItems={expandedBranches}
                                            onExpandedItemsChange={onExpandedBranchesChange}
                                            onSelectedItemsChange={onSelectedBranchesChange}
                                        />
                                    </>
                                    : null
                                }
                            </div>
                            : null}
                    </div>
                </Col>
            </Row>
    });
}