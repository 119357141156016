import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function UserReportDetail() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [report, setReport] = useState(null);
    const [modal, setModal] = useState(false);

    const params = useParams();

    const getReport = async () => {
        if(params.id != null){
            await axiosPrivate.get(`/api/reports/${params.id}`)
            .then(response =>{
                setReport(response.data);
            });
        }
    };

    useEffect(() => {
        getReport();
    }, []);

    const toggle = () => setModal(!modal);

    const handleDelete = async (e) => {
        e.preventDefault();

        try{
            console.log('here')
            await axiosPrivate.delete(`api/reports/${params.id}`);
            navigate('/reports');
        }
        catch(err){
            console.log(err);
        }
    }

    return(
        report != null ?
        <Card>
            <CardBody>
                <CardTitle tag="h5">Report Details</CardTitle>
                <CardText>
                    Type: {report.type}
                </CardText>
                <CardText>
                    Status: {report.status}
                </CardText>
                <CardText>
                    Creation Date: {report.creationDate}
                </CardText>
                {report.presignedURL != null ? 
                    <CardText>
                        File: <a href={report.presignedURL} target='_blank'>Download</a>
                    </CardText> 
                : null}
                {report.status != 'Processing' ?
                    <Button className='btn-danger' onClick={toggle}>Delete</Button>
                : null}
                <Link to="/reports">
                    <Button className='btn-primary'>Back</Button>
                </Link>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Delete Report</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this report?
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-danger" onClick={handleDelete}>Delete</Button> 
                        <Button className="btn-primary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>
    : null
    );

}